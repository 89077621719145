import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { adminRoute } from "..";

export const devToolsRoute = createRoute({
  path: "devtools",
  getParentRoute: () => adminRoute,
  component: lazyRouteComponent(() => import("./+Layout")),
  beforeLoad: () => ({
    breadcrumb: "Dev Tools",
  }),
});

export const devToolsIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => devToolsRoute,
  component: lazyRouteComponent(() => import("./+DevTools")),
  // beforeLoad: () => ({
  //   breadcrumb: "Dev Tools",
  // }),
});
