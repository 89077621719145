import { ApiErrorResponse } from "@common/api/api-types";
import { ApiError } from "@givenwell/management-api";

export function getErrorMessage(error: unknown) {
  if (!error) {
    return "Unknown error";
  }

  if (error instanceof ApiError) {
    const bodyMessage = error?.body?.message;
    if (typeof bodyMessage === "string") {
      return bodyMessage;
    }

    const apiError = error as ApiErrorResponse;
    if (apiError.body) {
      // FluentValidation error.
      if (Array.isArray(apiError.body.data) && apiError.body.data.length > 0) {
        return (apiError.body.data?.[0]?.error_message as string) ?? "Unknown error";
      }

      // .NET WebAPI error.
      if (typeof apiError.body.data === "object" && apiError.body.data !== null) {
        for (const [key, value] of Object.entries(apiError.body.data)) {
          if (typeof value === "string") {
            return `${key} error: ` + value;
          }
          if (Array.isArray(value) && value.length > 0) {
            const firstValue = value[0];
            if (typeof firstValue === "string") {
              return `${key} error: ` + value[0];
            }
          }
        }
      }

      // Some other type of error that happens sometimes??
      if ("title" in apiError.body && typeof apiError.body.title === "string") {
        return apiError.body.title;
      }

      if (apiError?.body?.message && typeof apiError.body.message === "string") {
        return apiError.body.message;
      }
    } else {
      return apiError.status.toString();
    }
  }

  if (error instanceof Error) {
    return error?.message?.toString() || "Unknown error";
  }

  if (typeof error === "string") {
    return error;
  }

  if (typeof error === "number") {
    return error.toString();
  }

  return "Unknown error";
}
