import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { fallback, number, object, optional } from "valibot";
import { rootRoute } from "..";

export const newSupplierRoute = createRoute({
  path: "new-supplier",
  getParentRoute: () => rootRoute,
  validateSearch: valibotSearchValidator(object({ pageIdx: fallback(optional(number()), undefined) })),
  component: lazyRouteComponent(() => import("./+NewSupplier")),
});
