import { Text } from "@givenwell/components";
import { CSS, styled } from "@givenwell/stitches";
import { colors } from "@givenwell/theme";
import * as BaseNavigationMenu from "@radix-ui/react-navigation-menu";
import { IconHelp } from "@tabler/icons-react";
import { Link, LinkComponent, LinkProps, RegisteredRouter } from "@tanstack/react-router";
import { ReactNode } from "react";

export function NavigationMenu({ children }: { children?: ReactNode }) {
  return (
    <Root orientation="vertical">
      <List>{children}</List>
    </Root>
  );
}

const Root = styled(BaseNavigationMenu.Root, {
  width: "100%",
  height: "100%",
  "& > *": {
    width: "100%",
    height: "100%",
  },
});
const List = styled(BaseNavigationMenu.List, {
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

type NavigationMenuLinkProps<TFrom extends string = string, TTo extends string = ""> = LinkProps<
  RegisteredRouter,
  TFrom,
  TTo,
  TFrom,
  TTo
> & {
  css?: CSS;
  icon?: ReactNode;
  activeIcon?: ReactNode;
};

export function NavigationMenuLink<TFrom extends string = string, TTo extends string = "">({
  css,
  children,
  icon,
  activeIcon,
  ...props
}: NavigationMenuLinkProps<TFrom, TTo>) {
  return (
    <Item css={css}>
      <BaseNavigationMenu.Link asChild>
        <StyledLink {...(props as any)}>
          {({ isActive }) => (
            <>
              {icon && <IconBox>{isActive ? activeIcon || icon : icon}</IconBox>}
              {children}
            </>
          )}
        </StyledLink>
      </BaseNavigationMenu.Link>
    </Item>
  );
}

type NavigationMenuAProps = {
  css?: CSS;
  icon?: ReactNode;
  children?: ReactNode;
  href: string;
};

export function NavigationMenuA({ css, children, icon, ...props }: NavigationMenuAProps) {
  return (
    <Item css={css}>
      <BaseNavigationMenu.Link asChild>
        <StyledA {...props}>
          {icon && <IconBox>{icon}</IconBox>}
          {children}
        </StyledA>
      </BaseNavigationMenu.Link>
    </Item>
  );
}

const Item = styled(BaseNavigationMenu.Item, {});

const linkCSS: CSS = {
  height: 40,
  display: "flex",
  alignItems: "center",
  px: (40 - 24) / 2,
  gap: (40 - 24) / 2,

  color: colors.gray700,
  fontScale: "sm",
  weight: 400,
  rounded: 8,

  "&.active": {
    weight: 500,
    bg: colors.blue100,
    color: colors.blue800,
  },

  mb: 4,
};

const StyledLink = styled(Link, linkCSS) as unknown as LinkComponent<"a">;
const StyledA = styled("a", linkCSS);

const IconBox = styled("span", {
  display: "flex",
  items: "center",
  justify: "center",
  size: 24,

  color: colors.gray500,
  ".active > &": {
    color: colors.blue800,
  },
});

export function NavigationMenuHeading({ children }: { children?: ReactNode }) {
  return (
    <Item>
      <Text
        css={{
          color: "rgba(0, 0, 0, 0.5)",
          letterSpacing: "0.6px",
          fontScale: "2xs",
          weight: 500,
          textTransform: "uppercase",
          display: "flex",
          height: 40,
          pt: 4,
          px: 8,
          items: "center",
        }}
      >
        {children}
      </Text>
    </Item>
  );
}

export function NavigationMenuSeparator() {
  return (
    <Item>
      <Hr />
    </Item>
  );
}

const Hr = styled("hr", { height: 1, backgroundColor: "rgba(0, 0, 0, 0.2)", mx: 8, mt: 16, mb: 8 });

export function SupplierNavigationMenuItems() {
  return (
    <>
      <NavigationMenuA
        href="mailto:support@givenwell.com?subject=Supplier%20Support%20Request"
        css={{ mt: "auto" }}
        icon={<IconHelp />}
      >
        Help & Support
      </NavigationMenuA>
    </>
  );
}

export function SupporterNavigationMenuItems() {
  return (
    <>
      <NavigationMenuA
        href="mailto:support@givenwell.com?subject=Employer%20Support%20Request"
        css={{ mt: "auto" }}
        icon={<IconHelp />}
      >
        Help & Support
      </NavigationMenuA>
    </>
  );
}
