import { Outlet, createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { calendarsRoute } from "..";

export const calendarRoute = createRoute({
  path: "$calendarId",
  getParentRoute: () => calendarsRoute,
  component: Outlet,
  beforeLoad: () => ({
    breadcrumb: "Edit Calendar",
  }),
});

export const editCalendarIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => calendarRoute,
  component: lazyRouteComponent(() => import("./+EditCalendarSettings")),
});
