import { useRouterState } from "@tanstack/react-router";

export function getIsAssumed() {
  return localStorage.getItem("management.assumed.at") !== null;
}

export function useSupplierId() {
  const state = useRouterState();
  const routeParams = state.matches[0]?.params;
  const idFromURL = routeParams && "supplierId" in routeParams ? routeParams.supplierId : undefined;
  if (idFromURL) {
    return idFromURL;
  }
  return undefined;
}

export function useSupporterId() {
  const state = useRouterState();
  const routeParams = state.matches[0]?.params;
  const idFromURL = routeParams && "supporterId" in routeParams ? routeParams.supporterId : undefined;
  if (idFromURL) {
    return String(idFromURL);
  }
  return undefined;
}
