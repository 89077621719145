import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { historyRoute } from "..";

export const batchDetailsRoute = createRoute({
  path: "$batchId",
  getParentRoute: () => historyRoute,
  beforeLoad: () => ({
    breadcrumb: "View Details",
  }),
  component: lazyRouteComponent(() => import("./+BatchDetails")),
});
