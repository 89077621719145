import { rootRoute } from "@/pages";
import { checkAuth, checkSupplierRole } from "@/utils/router";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";

export const newListingRoute = createRoute({
  path: "supplier/$supplierId/listings/new",
  getParentRoute: () => rootRoute,
  beforeLoad: async ({ params }) => {
    await checkAuth();
    await checkSupplierRole(params.supplierId);
  },
  component: lazyRouteComponent(() => import("./+NewListing")),
});
