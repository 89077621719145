import { PurchaseStatus, PurchaseType } from "@/api/sales";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { array, fallback, number, object, optional, pipe, string, toMinValue, transform } from "valibot";
import { supplierRoute } from "..";

const PURCHASE_STATUSES = ["cancelled", "completed", "disputed", "refunded", "upcoming"] as PurchaseStatus[];
const PURCHASE_TYPES = ["booking", "subscription", "order", "pass", "ticket"] as PurchaseType[];

const searchSchema = object({
  q: fallback(optional(string(), ""), ""),
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
  start: fallback(pipe(optional(string()), transform(optionalStringToOptionalDate)), undefined),
  end: fallback(pipe(optional(string()), transform(optionalStringToOptionalDate)), undefined),
  "purchase-statuses": fallback(optional(array(string()), PURCHASE_STATUSES), PURCHASE_STATUSES),
  "purchase-types": fallback(optional(array(string()), PURCHASE_TYPES), PURCHASE_TYPES),
});

export const salesRoute = createRoute({
  path: "sales",
  getParentRoute: () => supplierRoute,
  beforeLoad: () => ({
    breadcrumb: "Sales",
  }),
});

export const salesIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => salesRoute,
  component: lazyRouteComponent(() => import("./+SupplierSales")),
  validateSearch: valibotSearchValidator(searchSchema),
});

function optionalStringToOptionalDate(dateStr: string = "") {
  const date = new Date(dateStr);
  if (!isNaN(date.getTime())) {
    return date;
  }
  return undefined;
}
