import { isAdminRole, isSupplierRole, isSupporterRole, useRoleQuery } from "@/api/roles";
import { Center, Spinner } from "@givenwell/components";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

export default function Home() {
  const navigate = useNavigate();
  const rolesQuery = useRoleQuery();

  useEffect(() => {
    if (rolesQuery.isPending) {
      return;
    }
    const roles = rolesQuery.data?.roles || [];
    if (roles.length === 0) {
      navigate({
        to: "/sign-in",
      });
      return;
    }
    const adminRole = roles.find(role => isAdminRole(role));
    const firstRole = roles[0]!;

    if (adminRole) {
      navigate({
        to: "/admin",
      });
      return;
    }
    if (isSupplierRole(firstRole)) {
      navigate({
        to: "/supplier/$supplierId",
        params: { supplierId: firstRole.supplier_id },
      });
      return;
    }
    if (isSupporterRole(firstRole)) {
      navigate({
        to: "/supporter/$supporterId",
        params: { supporterId: firstRole.supporter_id },
      });
      return;
    }
    // Unknown role, i'm kicking you out >:)
    navigate({
      to: "/sign-in",
    });
  }, [navigate, rolesQuery.data, rolesQuery.isError, rolesQuery.isPending]);

  return (
    <Center css={{ width: "100%", height: "100%" }}>
      <Spinner />
    </Center>
  );
}
