import { Outlet, createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { settingsRoute } from "..";

export const businessRoute = createRoute({
  path: "business",
  getParentRoute: () => settingsRoute,
  component: Outlet,
});

export const businessIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => businessRoute,
  component: lazyRouteComponent(() => import("./+BusinessSettings")),
});
