import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { supplierRoute } from "..";

export const supplierLocationsRoute = createRoute({
  path: "locations",
  getParentRoute: () => supplierRoute,
  component: lazyRouteComponent(() => import("./+SupplierLocations")),
  beforeLoad: () => ({
    breadcrumb: "Locations",
  }),
});
