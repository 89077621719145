import { createGlobalState, useGlobalState } from "@givenwell/hooks";

export const registrationState = createGlobalState<ServiceWorkerRegistration | undefined>(undefined);
const notificationPermissionState = createGlobalState<NotificationPermission>("default");
function handlePermissionChange() {
  if (!("Notification" in window)) return notificationPermissionState.setValue("default");
  notificationPermissionState.setValue(Notification.permission);
}

export async function registerServiceWorker() {
  if (!("serviceWorker" in navigator)) return;

  try {
    const registration = await navigator.serviceWorker.register("/service-worker.js");

    setInterval(
      () => {
        registration.update().catch(e => {
          console.error("Failed to update service worker", e);
        });
      },
      1000 * 60 * 60,
    );

    registrationState.setValue(registration);
  } catch (e) {
    console.error("Failed to register service worker", e);
  }
  handlePermissionChange();
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  return Uint8Array.from([...rawData].map(char => char.charCodeAt(0)));
}

async function getSubscriptionIfAvailable() {
  const registration = await navigator.serviceWorker.getRegistration();
  if (!registration) return null;
  return await registration.pushManager.getSubscription();
}

export async function subscribeToPush() {
  const subscription = await getSubscriptionIfAvailable();
  if (subscription) {
    return subscription;
  }
  const registration = registrationState.getValue();
  console.log(registration);
  if (!registration) return;

  const sub = await registration.pushManager.getSubscription();
  if (sub) {
    // already registered, but theres no harm in re-registering
  }

  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(import.meta.env.VITE_VAPID_PUBLIC_KEY),
  };
  console.log(import.meta.env.VITE_VAPID_PUBLIC_KEY);

  const pushSubscription = await registration.pushManager.subscribe(subscribeOptions);

  handlePermissionChange();
  return pushSubscription;
}

export async function unsubscribeFromPush() {
  const registration = registrationState.getValue();
  if (!registration) return;

  const sub = await registration.pushManager.getSubscription();
  if (!sub) return;

  const res = await sub.unsubscribe();

  handlePermissionChange();

  return res;
}

export function useNotificationPermission() {
  return useGlobalState(notificationPermissionState)[0];
}
