import { countriesQuery } from "@/api/location";
import { supplierQuery } from "@/api/supplier";
import { availableMarketsQuery, registeredMarketsQuery } from "@/api/supplierMarkets";
import { queryClient } from "@/api/utils";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { settingsRoute } from "..";

export const marketSettingsRoute = createRoute({
  path: "markets",
  getParentRoute: () => settingsRoute,
  loader: ({ params: { supplierId } }) => {
    queryClient.prefetchQuery(supplierQuery(supplierId));
    queryClient.prefetchQuery(registeredMarketsQuery(supplierId));
    queryClient.prefetchQuery(availableMarketsQuery);
    queryClient.prefetchQuery(countriesQuery);
  },
  component: lazyRouteComponent(() => import("./+Markets")),
});
