import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { fallback, number, object, optional, picklist, pipe, toMinValue } from "valibot";
import { adminRoute } from "..";

export const surplusesRoute = createRoute({
  path: "surpluses",
  getParentRoute: () => adminRoute,
  beforeLoad: () => ({
    breadcrumb: "Surpluses",
  }),
});

const searchSchema = object({
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
  status: fallback(
    optional(picklist(["unprocessed", "notified", "refunded", "invoiced"]), "unprocessed"),
    "unprocessed",
  ),
});

export const surplusesIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => surplusesRoute,
  component: lazyRouteComponent(() => import("./+SurplusesPage")),
  validateSearch: valibotSearchValidator(searchSchema),
});
