import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { cancellationPoliciesRoute } from "..";

export const cancellationPolicyRoute = createRoute({
  path: "$policyId",
  getParentRoute: () => cancellationPoliciesRoute,
  component: lazyRouteComponent(() => import("./+EditPolicy")),
  beforeLoad: () => ({
    breadcrumb: "Cancellation Policy",
  }),
});
