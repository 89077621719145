import { Spinner } from "@givenwell/components";
import { CSS, VariantProps, keyframes, styled } from "@givenwell/stitches";
import { colors } from "@givenwell/theme";
import { ButtonHTMLAttributes, ForwardedRef, ReactNode, forwardRef, useState } from "react";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof Root> & {
    // Require a variant to be provided, just to be explicit.
    variant: NonNullable<VariantProps<typeof Root>["variant"]>;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    loading?: boolean;
    loadingText?: ReactNode;
    css?: CSS;
    as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  };

/**
 * A button component.
 *
 * Variants: `plain` | `outlined` | `tonal` | `filled`
 *
 * Colors: `primary` | `neutral` | `green` | `red`
 *
 * Sizes: `xs` | `sm` | `md` | `lg`
 */
export const Button = forwardRef(function Button(
  { children, loading, leftIcon, rightIcon, loadingText, ...props }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [prevLoadingState, setPrevLoadingState] = useState(loading);

  // Turns out you can set state directly in your render function, who woulda' thought?
  // https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
  if (loading !== prevLoadingState) {
    setIsFirstRender(false);
    setPrevLoadingState(loading);
  }
  // Run the fancy loading animation only when the loading state changes, not on
  // mount, and not if you have loading text (the animation requires the button
  // width to not change).
  const loadingAnimationState: "start" | "end" | undefined =
    loadingText ? undefined
    : loading ? "start"
    : isFirstRender ? undefined
    : "end";

  return (
    <Root type="button" {...props} visuallyDisabled={props.disabled} disabled={props.disabled || loading} ref={ref}>
      {loadingText && loading && (
        <Fadable loading={loadingAnimationState}>
          <Icon>
            <Spinner css={{ size: 16 }} />
          </Icon>
        </Fadable>
      )}
      {leftIcon && (
        <Fadable loading={loadingAnimationState}>
          <Icon>{leftIcon}</Icon>
        </Fadable>
      )}
      <Fadable loading={loadingAnimationState}>{loading ? loadingText || children : children}</Fadable>
      {rightIcon && (
        <Fadable loading={loadingAnimationState}>
          <Icon>{rightIcon}</Icon>
        </Fadable>
      )}
      <SpinnerBox loading={loadingAnimationState}>
        <Spinner css={{ size: 16 }} />
      </SpinnerBox>
    </Root>
  );
});

const Root = styled("button", {
  position: "relative",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: 500,
  transition: "all 0.15s ease-in-out",
  cursor: "pointer",
  userSelect: "none",
  border: "1px solid transparent",
  flexShrink: "0",

  "&:focus-visible": {
    outline: "none",
    boxShadow: `0 0 0 2px white, 0 0 0 4px ${colors.blue500}`,
  },
  "&:active": {
    transitionDuration: "0.05s",
  },

  variants: {
    color: {
      primary: {
        "--button-fill": colors.blue500,
        "--button-fill2": colors.blue600,
        "--button-fill3": colors.blue700,
        "--button-on-fill": colors.white,

        "--button-tonal": colors.blue100,
        "--button-tonal2": colors.blue200,
        "--button-tonal3": colors.blue300,
        "--button-on-tonal": colors.blue600,
        "--button-on-tonal2": colors.blue600,
        "--button-on-tonal3": colors.blue700,

        "--button-outline": colors.blue500,
        "--button-outline2": colors.blue600,
        "--button-outline3": colors.blue700,

        "--button-text": colors.blue500,
        "--button-text2": colors.blue600,
        "--button-text3": colors.blue700,
      },
      neutral: {
        "--button-fill": colors.gray500,
        "--button-fill2": colors.gray600,
        "--button-fill3": colors.gray700,
        "--button-on-fill": colors.white,

        "--button-tonal": colors.gray0,
        "--button-tonal2": colors.gray100,
        "--button-tonal3": colors.gray200,
        "--button-on-tonal": colors.gray900,
        "--button-on-tonal2": colors.gray900,
        "--button-on-tonal3": colors.gray900,

        "--button-outline": colors.gray500,
        "--button-outline2": colors.gray600,
        "--button-outline3": colors.gray700,

        "--button-text": colors.gray900,
        "--button-text2": colors.gray900,
        "--button-text3": colors.gray900,
      },
      green: {
        "--button-fill": colors.green400,
        "--button-fill2": colors.green500,
        "--button-fill3": "#007400",
        "--button-on-fill": colors.white,

        "--button-tonal": colors.green100,
        "--button-tonal2": colors.green200,
        "--button-tonal3": colors.green300,
        "--button-on-tonal": colors.green500,
        "--button-on-tonal2": colors.green600,
        "--button-on-tonal3": colors.green700,

        "--button-outline": colors.green400,
        "--button-outline2": colors.green500,
        "--button-outline3": colors.green600,

        "--button-text": colors.green400,
        "--button-text2": colors.green500,
        "--button-text3": colors.green700,
      },
      red: {
        "--button-fill": colors.red400,
        "--button-fill2": colors.red500,
        "--button-fill3": colors.red600,
        "--button-on-fill": colors.white,

        "--button-tonal": colors.red100,
        "--button-tonal2": colors.red200,
        "--button-tonal3": colors.red300,
        "--button-on-tonal": colors.red500,
        "--button-on-tonal2": colors.red600,
        "--button-on-tonal3": colors.red700,

        "--button-outline": colors.red400,
        "--button-outline2": colors.red500,
        "--button-outline3": colors.red600,

        "--button-text": colors.red400,
        "--button-text2": colors.red500,
        "--button-text3": colors.red700,
      },
      orange: {
        "--button-fill": colors.orange400,
        "--button-fill2": colors.orange500,
        "--button-fill3": colors.orange600,
        "--button-on-fill": colors.white,

        "--button-tonal": colors.orange100,
        "--button-tonal2": colors.orange200,
        "--button-tonal3": colors.orange300,
        "--button-on-tonal": colors.orange500,
        "--button-on-tonal2": colors.orange600,
        "--button-on-tonal3": colors.orange700,

        "--button-outline": colors.orange400,
        "--button-outline2": colors.orange500,
        "--button-outline3": colors.orange600,

        "--button-text": colors.orange600,
        "--button-text2": colors.orange700,
        "--button-text3": colors.orange700,
      },
    },
    variant: {
      plain: {
        backgroundColor: "transparent",
        color: "var(--button-text)",

        "&:hover": {
          color: "var(--button-on-tonal2)",
        },
        "&:active": {
          color: "var(--button-on-tonal3)",
        },
      },
      outlined: {
        backgroundColor: "transparent",
        color: "var(--button-text)",
        borderColor: "var(--button-outline)",

        "&:hover": {
          color: "var(--button-text2)",
          borderColor: "var(--button-outline2)",
        },
        "&:active": {
          color: "var(--button-text3)",
          borderColor: "var(--button-outline3)",
        },
      },
      tonal: {
        backgroundColor: "var(--button-tonal)",
        color: "var(--button-on-tonal)",

        "&:hover": {
          backgroundColor: "var(--button-tonal2)",
          color: "var(--button-on-tonal2)",
        },
        "&:active": {
          backgroundColor: "var(--button-tonal3)",
          color: "var(--button-on-tonal3)",
        },
      },
      filled: {
        backgroundColor: "var(--button-fill)",
        color: "var(--button-on-fill)",

        "&:hover": {
          backgroundColor: "var(--button-fill2)",
        },
        "&:active": {
          backgroundColor: "var(--button-fill3)",
        },
      },
    },
    size: {
      xs: {
        height: "24px",
        padding: "0 8px",
        gap: "4px",
        borderRadius: "2px",
        fontSize: 12,
      },
      sm: {
        height: "32px",
        padding: "0 12px",
        gap: "8px",
        borderRadius: "4px",
        fontSize: 14,
      },
      md: {
        height: "40px",
        padding: "0 16px",
        gap: "8px",
        borderRadius: "8px",
        fontSize: 16,
        borderWidth: 2,
      },
      lg: {
        height: "48px",
        padding: "0 16px",
        gap: "8px",
        borderRadius: "8px",
        fontSize: 18,
        borderWidth: 2,
      },
    },
    visuallyDisabled: {
      true: {
        "--button-fill": colors.gray100,
        "--button-fill2": colors.gray100,
        "--button-fill3": colors.gray100,
        "--button-on-fill": colors.gray500,

        "--button-tonal": colors.gray100,
        "--button-tonal2": colors.gray100,
        "--button-tonal3": colors.gray100,
        "--button-on-tonal": colors.gray500,
        "--button-on-tonal2": colors.gray500,
        "--button-on-tonal3": colors.gray500,

        "--button-outline": colors.gray500,
        "--button-outline2": colors.gray500,
        "--button-outline3": colors.gray500,

        "--button-text": colors.gray500,
        "--button-text2": colors.gray500,
        "--button-text3": colors.gray500,

        cursor: "default",
      },
    },
  },
  compoundVariants: [
    {
      variant: "plain",
      size: "sm",
      css: {
        padding: "0 8px",
      },
    },
    {
      variant: "plain",
      size: "md",
      css: {
        padding: "0 8px",
      },
    },
    {
      variant: "plain",
      size: "lg",
      css: {
        padding: "0 8px",
      },
    },
  ],
  defaultVariants: {
    variant: "plain",
    color: "primary",
    size: "md",
  },
});

const Icon = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const spinnerFadeIn = keyframes({
  "0%": { opacity: 0, transform: "translateY(-12px)" },
  "100%": { opacity: 1, transform: "translateY(0px)" },
});
const fadeOut = keyframes({
  "0%": { opacity: 1, transform: "translateY(0px)" },
  "100%": { opacity: 0, transform: "translateY(12px)" },
});

const SpinnerBox = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  inset: 0,
  opacity: 0,

  variants: {
    loading: {
      start: {
        animation: `${spinnerFadeIn()} 250ms cubic-bezier(0.4,0,0.2,1) forwards`,
      },
      end: {
        animation: `${fadeOut()} 250ms cubic-bezier(0.4,0,0.2,1) forwards`,
      },
    },
  },
});

const Fadable = styled("div", {
  variants: {
    loading: {
      start: {
        animation: `${fadeOut()} 250ms cubic-bezier(0.4,0,0.2,1) forwards`,
      },
      end: {
        animation: `${spinnerFadeIn()} 250ms cubic-bezier(0.4,0,0.2,1) forwards`,
      },
    },
  },
});
