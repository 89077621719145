import {
  AdminAssumableRole,
  AssumableRole,
  GetAssumableRolesResponse,
  SupplierAssumableRole,
  SupporterAssumableRole,
} from "@givenwell/management-api";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { api, queryClient } from "./utils";

const localStorageKey = "queryCache.roles.getAssumableRoles";

function getInitialRoles(): GetAssumableRolesResponse | undefined {
  try {
    const data = localStorage.getItem(localStorageKey);
    if (data) {
      return JSON.parse(data);
    }
  } catch {
    return undefined;
  }
}

export const rolesQuery = queryOptions({
  queryKey: ["roles"],
  queryFn: async () => {
    const res = await api.roles.getAssumableRoles();
    if (res) {
      localStorage.setItem(localStorageKey, JSON.stringify(res));
    }
    return res;
  },
  placeholderData: getInitialRoles,
});

export function getRolesFromCache() {
  const queryCacheData = queryClient.getQueryData(rolesQuery.queryKey);
  if (queryCacheData) {
    return queryCacheData;
  }
  return getInitialRoles();
}

export function useRoleQuery() {
  return useQuery(rolesQuery);
}

export function isSupplierRole(role: AssumableRole): role is SupplierAssumableRole {
  return role.$type === "supplier";
}

export function isSupporterRole(role: AssumableRole): role is SupporterAssumableRole {
  return role.$type === "supporter";
}

export function isAdminRole(role: AssumableRole): role is AdminAssumableRole {
  return role.$type === "admin";
}
