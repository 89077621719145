import "./styles/globals.css";
import "./styles/reset.css";

import "@fontsource/dm-sans/400.css";
import "@fontsource/dm-sans/500.css";
import "@fontsource/dm-sans/700.css";

import ReactDOM from "react-dom/client";
import { App } from "./App";
import { registerServiceWorker } from "./hooks/useServiceWorker";
import { handleDynamicImportErrors } from "./utils/vite";

handleDynamicImportErrors();
registerServiceWorker();

if (import.meta.env.PROD) {
  import("./third-party")
    .then(module => {
      module.initialize();
    })
    .catch(console.error);
}

const rootElement = document.getElementById("root") as HTMLElement;

ReactDOM.createRoot(rootElement).render(<App />);

const appleTouchIcon = document.querySelector("link[rel=apple-touch-icon]") as HTMLLinkElement;
const manifest = document.querySelector("link[rel=manifest]") as HTMLLinkElement;

if (import.meta.env.DEV) {
  appleTouchIcon.href = "/apple-touch-icon-test.png";
  manifest.href = "/site-test.webmanifest";
}
if (import.meta.env.VITE_TEST) {
  appleTouchIcon.href = "/apple-touch-icon-test.png";
  manifest.href = "/site-test.webmanifest";
}
if (import.meta.env.VITE_DEMO) {
  appleTouchIcon.href = "/apple-touch-icon-demo.png";
  manifest.href = "/site-demo.webmanifest";
}
