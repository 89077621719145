import { queryOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "./utils";

export const registeredMarketsQuery = (supplierId: string) =>
  queryOptions({
    queryKey: ["registered-markets", supplierId],
    queryFn: async () => {
      const res = await api.supplierMarkets.getMarketRegistrationsForSupplier({ supplierId });
      return res.markets;
    },
  });
export const availableMarketsQuery = queryOptions({
  queryKey: ["available-markets"],
  queryFn: async () => {
    const res = await api.supplierMarkets.getAvailableMarkets();
    return res.markets;
  },
});

export function useRegisterToMarketMutation() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: api.supplierMarkets.registerToMarket,
    onSuccess(data, variables) {
      qc.invalidateQueries(registeredMarketsQuery(variables.supplierId));
    },
  });
}
