import { isAdminRole, useRoleQuery } from "@/api/roles";
import { getIsAssumed } from "@/utils/roles";
import { colors } from "@givenwell/theme";
import { IconAlertTriangle } from "@tabler/icons-react";
import { ReactNode, useEffect, useState } from "react";

export function Beta({ children }: { children?: ReactNode }) {
  const roles = useRoleQuery();
  const hasAdminRole = roles.data?.roles.some(isAdminRole) || false;

  const isAdmin = hasAdminRole || getIsAssumed() || false;
  const optionKeyPressed = useOptionKeyPressed();
  const controlToggle = useControlToggle();

  if (controlToggle) {
    children = (
      <div
        style={{
          boxShadow: `0 0 0 1px ${colors.red400}`,
          padding: "4px",
          borderRadius: 8,
          backgroundColor: colors.red100,
          position: "relative",
        }}
      >
        {children}
        <div
          style={{
            display: "flex",
            color: colors.red500,
            fontSize: 12,
            fontWeight: 500,
            alignItems: "center",
            position: "absolute",
            top: 2,
            right: 2,
            gap: 4,
          }}
        >
          <IconAlertTriangle width="16px" height="16px" />
          <span>Beta</span>
        </div>
      </div>
    );
  }

  return isAdmin && !optionKeyPressed ? children : null;
}

function useOptionKeyPressed() {
  const [isPressed, setIsPressed] = useState(false);
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Alt") {
        setIsPressed(true);
      }
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Alt") {
        setIsPressed(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  return isPressed;
}

function useControlToggle() {
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Control") {
        setIsActive(a => !a);
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return isActive;
}
