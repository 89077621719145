import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { teamRoute } from "..";

export const teamMemberRoute = createRoute({
  path: "$userId",
  getParentRoute: () => teamRoute,
  component: lazyRouteComponent(() => import("./+TeamPage")),
  beforeLoad: () => ({
    breadcrumb: "Edit Team Member",
  }),
});
