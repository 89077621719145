import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { fallback, object, optional, picklist, pipe, string, transform } from "valibot";
import { supplierRoute } from "..";

export const BOOKING_VIEWS = ["schedule", "year", "month", "week", "day"] as const;
export type BookingView = (typeof BOOKING_VIEWS)[number];

const searchSchema = object({
  calendar: fallback(optional(string()), undefined),
  view: fallback(optional(picklist(BOOKING_VIEWS), "week"), "week"),
  date: fallback(pipe(optional(string(), ""), transform(stringToDate)), new Date()),
});

export const bookingsRoute = createRoute({
  path: "bookings",
  getParentRoute: () => supplierRoute,
  component: lazyRouteComponent(() => import("./+Bookings")),
  validateSearch: valibotSearchValidator(searchSchema),
  beforeLoad: () => ({
    breadcrumb: "Bookings",
  }),
});

function stringToDate(dateStr: string) {
  const date = new Date(dateStr);
  if (!isNaN(date.getTime())) {
    return date;
  }
  return new Date();
}
