import { Beta } from "@/components/headless/Beta";
import {
  NavigationMenu,
  NavigationMenuHeading,
  NavigationMenuLink,
  SupplierNavigationMenuItems,
} from "@/components/layout/NavigationMenu";
import { IconArrowLeft } from "@tabler/icons-react";
import { getRouteApi } from "@tanstack/react-router";

const route = getRouteApi("/supplier/$supplierId/settings");

export function Sidebar() {
  const { supplierId } = route.useParams();

  return (
    <NavigationMenu>
      <NavigationMenuLink
        to="/supplier/$supplierId"
        params={{ supplierId }}
        icon={<IconArrowLeft />}
        activeOptions={{ exact: true }}
      >
        Back
      </NavigationMenuLink>
      <NavigationMenuHeading>Settings</NavigationMenuHeading>
      <NavigationMenuLink
        to="/supplier/$supplierId/settings"
        params={{ supplierId }}
        activeOptions={{
          exact: true,
        }}
      >
        Public Profile
      </NavigationMenuLink>
      <NavigationMenuLink to="/supplier/$supplierId/settings/business" params={{ supplierId }}>
        Business Details
      </NavigationMenuLink>
      <NavigationMenuLink to="/supplier/$supplierId/settings/payment" params={{ supplierId }}>
        Payment Details
      </NavigationMenuLink>
      <NavigationMenuLink to="/supplier/$supplierId/settings/locations" params={{ supplierId }}>
        Locations
      </NavigationMenuLink>
      <NavigationMenuLink to="/supplier/$supplierId/settings/team" params={{ supplierId }}>
        Users
      </NavigationMenuLink>
      <NavigationMenuLink to="/supplier/$supplierId/settings/calendars" params={{ supplierId }}>
        Calendars
      </NavigationMenuLink>
      <NavigationMenuLink to="/supplier/$supplierId/settings/automations" params={{ supplierId }}>
        Post Sales Messages
      </NavigationMenuLink>
      <NavigationMenuLink to="/supplier/$supplierId/settings/refunds" params={{ supplierId }}>
        Refund Settings
      </NavigationMenuLink>
      <NavigationMenuLink to="/supplier/$supplierId/settings/legal" params={{ supplierId }}>
        Terms of Sale
      </NavigationMenuLink>
      <Beta>
        <NavigationMenuLink to="/supplier/$supplierId/settings/markets" params={{ supplierId }}>
          Markets
        </NavigationMenuLink>
      </Beta>
      <SupplierNavigationMenuItems />
    </NavigationMenu>
  );
}
