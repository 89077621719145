import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { fallback, number, object, optional, pipe, toMinValue } from "valibot";
import { supporterRoute } from "..";

const searchSchema = object({
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
});

export const walletRoute = createRoute({
  path: "wallet",
  beforeLoad: () => ({
    breadcrumb: "Wallet",
  }),
  getParentRoute: () => supporterRoute,
  validateSearch: valibotSearchValidator(searchSchema),
});

export const walletIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => walletRoute,
  component: lazyRouteComponent(() => import("./+Wallet")),
});
