import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { supportersRoute } from "..";

export const supporterRoute = createRoute({
  path: "$supporterId",
  getParentRoute: () => supportersRoute,
  component: lazyRouteComponent(() => import("./+SupporterPage")),
  beforeLoad: () => ({
    breadcrumb: "Edit Supporter",
  }),
});
