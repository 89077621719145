import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { settingsRoute } from "..";

export const refundSettingsRoute = createRoute({
  path: "refunds",
  getParentRoute: () => settingsRoute,
});

export const refundSettingsIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => refundSettingsRoute,
  component: lazyRouteComponent(() => import("./+RefundSettings")),
});
