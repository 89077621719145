import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { boolean, fallback, number, object, optional, pipe, toMinValue } from "valibot";
import { supporterRoute } from "..";

const searchSchema = object({
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 1000), toMinValue(10)), 1000),
  "include-archived": fallback(optional(boolean(), false), false),
});

export const allowancesRoute = createRoute({
  path: "allowances",
  getParentRoute: () => supporterRoute,
  component: lazyRouteComponent(() => import("./+Allowances")),
  validateSearch: valibotSearchValidator(searchSchema),
});
