import { api, auth } from "@/api/utils";
import { createRootRouteWithContext, createRoute, lazyRouteComponent, redirect } from "@tanstack/react-router";
import { ReactNode } from "react";
import Home from "./+Home";
import RootLayout from "./+RootLayout";

type RouterContext = {
  breadcrumb?: ReactNode;
  sidebar?: ReactNode;
};

export const rootRoute = createRootRouteWithContext<RouterContext>()({
  component: RootLayout,
  notFoundComponent: lazyRouteComponent(() => import("./+404")),
});

export const homeRoute = createRoute({
  path: "/",
  getParentRoute: () => rootRoute,
  component: Home,
  beforeLoad: async () => {
    if (auth.hasAccessToken()) {
      return;
    }
    try {
      await auth.refresh(true);
      await api.auth.getManagementUserInfo();
    } catch (e) {
      throw redirect({
        to: "/sign-in",
        replace: true,
      });
    }
  },
});
