import { Outlet, createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { settingsRoute } from "..";

export const teamRoute = createRoute({
  path: "team",
  getParentRoute: () => settingsRoute,
  component: Outlet,
});

export const teamIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => teamRoute,
  component: lazyRouteComponent(() => import("./+TeamTable")),
});
