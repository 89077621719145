import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { supporterRoute } from "..";

export const supporterTeamRoute = createRoute({
  path: "team",
  getParentRoute: () => supporterRoute,
  component: lazyRouteComponent(() => import("./+Team")),
  beforeLoad: () => ({
    breadcrumb: "Team",
  }),
});
