import { Spinner } from "@givenwell/components";
import { Outlet, ScrollRestoration } from "@tanstack/react-router";
import { Suspense } from "react";

export default function RootLayout() {
  return (
    <>
      <ScrollRestoration getKey={location => location.pathname} />
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    </>
  );
}
