import { getRolesFromCache, isAdminRole, isSupplierRole, isSupporterRole } from "@/api/roles";
import { api, auth } from "@/api/utils";
import { AnyRouteMatch, ParsedLocation, redirect } from "@tanstack/react-router";

export function persistSearch(match: AnyRouteMatch) {
  let key = match.pathname;
  if (key.endsWith("/")) {
    key = key.slice(0, key.length - 1);
  }
  if (Object.keys(match.search).length) {
    sessionStorage.setItem(key, JSON.stringify(match.search));
  } else {
    sessionStorage.removeItem(key);
  }
}

export function restoreSearch(location: ParsedLocation) {
  const key = location.pathname;
  const searchStr = sessionStorage.getItem(key);
  let search: any;
  try {
    search = JSON.parse(searchStr!);
  } catch {}
  if (search && !location.searchStr) {
    throw redirect({
      search: search,
    });
  }
}

export async function checkAuth() {
  if (!auth.hasAccessToken()) {
    try {
      await auth.refresh(true);
      await api.auth.getManagementUserInfo();
    } catch (e) {
      throw redirect({
        to: "/sign-in",
        replace: true,
      });
    }
  }
}

export async function checkSupplierRole(supplierId: string) {
  const roles = getRolesFromCache()?.roles || [];

  const adminRole = roles.find(role => isAdminRole(role));
  const role = roles.find(role => isSupplierRole(role) && role.supplier_id === supplierId);

  if (!role && !adminRole) {
    throw redirect({
      to: "/",
    });
  }
}

export async function checkSupporterRole(supporterId: string) {
  const roles = getRolesFromCache()?.roles || [];

  const adminRole = roles.find(role => isAdminRole(role));
  const role = roles.find(role => isSupporterRole(role) && role.supporter_id === supporterId);

  if (!role && !adminRole) {
    throw redirect({
      to: "/",
    });
  }
}

export async function checkAdminRole() {
  const roles = getRolesFromCache()?.roles;
  if (!roles?.some(isAdminRole)) {
    throw redirect({
      to: "/",
    });
  }
}
