import { GivenwellDeadBirdLogo } from "@/components/GivenwellLogo";
import { Button } from "@/components/ui/Button";
import { Card } from "@/components/ui/Card";
import { ButtonLink } from "@/components/ui/Link";
import { getErrorMessage } from "@/utils/error";
import { Heading, Text } from "@givenwell/components";
import { colors } from "@givenwell/theme";
import { ErrorComponentProps } from "@tanstack/react-router";
import { useState } from "react";

function reload() {
  // eslint-disable-next-line no-self-assign
  location.pathname = location.pathname;
}

export function ErrorComponent({ error }: ErrorComponentProps) {
  const [offline] = useState(!navigator.onLine);

  if (offline) {
    return <OfflinePage />;
  }

  return (
    <Card css={{ maxW: 600 }}>
      <GivenwellDeadBirdLogo css={{ mb: 16, color: colors.gray500 }} />
      <Heading as="h1" css={{ mb: 16, fontScale: "2xl" }}>
        This page isn't working
      </Heading>

      <Text css={{ mb: 16 }}>
        <b>Error:</b> {getErrorMessage(error)}
      </Text>

      <Text css={{ mb: 32 }}>Please try again later.</Text>

      <Button css={{ mb: 16, w: "100%" }} variant="filled" color="primary" onClick={reload}>
        Reload
      </Button>
      <ButtonLink css={{ w: "100%" }} variant="outlined" color="primary" to="/">
        Go Home
      </ButtonLink>
    </Card>
  );
}

function OfflinePage() {
  return (
    <Card css={{ maxW: 600 }}>
      <GivenwellDeadBirdLogo css={{ mb: 16, color: colors.gray500 }} />
      <Heading as="h1" css={{ mb: 16, fontScale: "2xl" }}>
        Connect to the internet
      </Heading>

      <Text css={{ mb: 32 }}>You're offline. Check your connection.</Text>

      <Button css={{ w: "100%" }} variant="filled" color="primary" onClick={reload}>
        Reload
      </Button>
    </Card>
  );
}
