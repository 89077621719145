import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { walletRoute } from "..";

export const walletVersionDetailsRoute = createRoute({
  path: "$version",
  getParentRoute: () => walletRoute,
  beforeLoad: () => ({
    breadcrumb: "Details",
  }),
  parseParams: ({ version, ...params }) => {
    const num = +version;
    if (!isFinite(num)) {
      throw new Error("Invalid wallet version");
    }
    return {
      ...params,
      version: Math.round(num),
    };
  },
  stringifyParams: ({ version, ...params }) => ({
    ...params,
    version: version.toString(),
  }),
  component: lazyRouteComponent(() => import("./+VersionDetails")),
});
