import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { supplierRoute } from "..";

export const supplierSettingsRoute = createRoute({
  path: "settings",
  getParentRoute: () => supplierRoute,
  component: lazyRouteComponent(() => import("./+SupplierSettings")),
  beforeLoad: () => ({
    breadcrumb: "Settings",
  }),
});
