import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { fallback, number, object, optional, pipe, string, toMinValue } from "valibot";
import { adminRoute } from "..";

export const suppliersRoute = createRoute({
  path: "suppliers",
  getParentRoute: () => adminRoute,
  beforeLoad: () => ({
    breadcrumb: "Suppliers",
  }),
});

const searchSchema = object({
  q: fallback(optional(string(), ""), ""),
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
});

export const suppliersIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => suppliersRoute,
  component: lazyRouteComponent(() => import("./+SuppliersPage")),
  validateSearch: valibotSearchValidator(searchSchema),
});
