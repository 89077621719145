import {
  NavigationMenu,
  NavigationMenuHeading,
  NavigationMenuLink,
  SupplierNavigationMenuItems,
} from "@/components/layout/NavigationMenu";
import { IconArrowLeft } from "@tabler/icons-react";
import { getRouteApi } from "@tanstack/react-router";

const route = getRouteApi("/supporter/$supporterId/settings");

export function Sidebar() {
  const { supporterId } = route.useParams();

  return (
    <NavigationMenu>
      <NavigationMenuLink
        to="/supporter/$supporterId"
        params={{ supporterId }}
        icon={<IconArrowLeft />}
        activeOptions={{ exact: true }}
      >
        Back
      </NavigationMenuLink>
      <NavigationMenuHeading>Settings</NavigationMenuHeading>
      <NavigationMenuLink to="/supporter/$supporterId/settings/users" params={{ supporterId }}>
        Admin Users
      </NavigationMenuLink>
      <NavigationMenuLink to="/supporter/$supporterId/settings/billing" params={{ supporterId }}>
        Billing Details
      </NavigationMenuLink>
      <NavigationMenuLink to="/supporter/$supporterId/settings/eap" params={{ supporterId }}>
        EAP Settings
      </NavigationMenuLink>
      <SupplierNavigationMenuItems />
    </NavigationMenu>
  );
}
