import { Outlet, createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { settingsRoute } from "..";

export const calendarsRoute = createRoute({
  path: "calendars",
  getParentRoute: () => settingsRoute,
  component: Outlet,
  beforeLoad: () => ({
    breadcrumb: "Calendars",
  }),
});

export const calendarsIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => calendarsRoute,
  component: lazyRouteComponent(() => import("./+CalendarSettings")),
});
