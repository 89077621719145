import { Heading, Text } from "@givenwell/components";
import { colors } from "@givenwell/theme";
import { IconExternalLink } from "@tabler/icons-react";
import { GivenwellLogo } from "./GivenwellLogo";
import { Card } from "./ui/Card";
import { ButtonLink } from "./ui/Link";

export function NotFound() {
  return (
    <>
      <Card>
        <GivenwellLogo color={colors.brand500} />

        <Heading as="h2" css={{ fontScale: "4xl", mt: 16, mb: 8 }}>
          Not found
        </Heading>

        <Text css={{ fontScale: "lg", mb: 24 }}>Sorry, there is nothing at this page.</Text>

        <ButtonLink
          css={{ w: "100%" }}
          size="lg"
          variant="filled"
          color="primary"
          to="/"
          rightIcon={<IconExternalLink />}
        >
          Go back home
        </ButtonLink>
      </Card>
    </>
  );
}
