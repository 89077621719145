import { beneficiaryQuery } from "@/api/airWallex";
import { queryClient } from "@/api/utils";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { fallback, number, object, optional, pipe, string, toMinValue } from "valibot";
import { settingsRoute } from "..";

const searchSchema = object({
  q: fallback(optional(string(), ""), ""),
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
});

export const payoutRoute = createRoute({
  path: "payment",
  getParentRoute: () => settingsRoute,
  loader: ({ params }) => {
    queryClient.prefetchQuery(beneficiaryQuery(params.supplierId));
  },
  component: lazyRouteComponent(() => import("./+PaymentPage")),
  validateSearch: valibotSearchValidator(searchSchema),
});
