import { Box, BoxProps, Flex, Text } from "@givenwell/components";
import { colors } from "@givenwell/theme";
import { ReactNode } from "react";

export type CardProps = Omit<BoxProps, "title"> & {
  title?: ReactNode;
  description?: ReactNode;
  actions?: ReactNode;
};
export function Card({ title, description, actions, children, ...props }: CardProps) {
  return (
    <Box
      {...props}
      css={{
        p: 16,
        rounded: 12,
        bg: "white",
        ...props.css,
      }}
    >
      {(title || description || actions) && (
        <Flex css={{ items: "flex-start", pb: 16 }}>
          <Flex css={{ flexDirection: "column", gap: 4, flex: 1 }}>
            <Text
              css={{
                fontScale: "lg",
                weight: 500,
                color: colors.gray900,
              }}
            >
              {title}
            </Text>
            {description && (
              <Text
                css={{
                  fontScale: "xs",
                  color: colors.gray500,
                }}
              >
                {description}
              </Text>
            )}
          </Flex>
          <Flex
            css={{
              position: "relative",
              gap: 4,
              top: -8,
              right: -8,
              height: 0,
              bottom: -8,
            }}
          >
            {actions}
          </Flex>
        </Flex>
      )}
      {children}
    </Box>
  );
}
