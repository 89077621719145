import { createRoute } from "@tanstack/react-router";
import { supporterRoute } from "..";
import { Sidebar } from "./Sidebar";

export const settingsRoute = createRoute({
  path: "settings",
  beforeLoad: async () => {
    return {
      sidebar: <Sidebar />,
    };
  },
  getParentRoute: () => supporterRoute,
});
