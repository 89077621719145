import { Outlet, createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { supplierRoute } from "..";

export const supplierCalendarsRoute = createRoute({
  path: "calendars",
  getParentRoute: () => supplierRoute,
  component: Outlet,
  beforeLoad: () => ({
    breadcrumb: "Calendars",
  }),
});

export const supplierCalendarsIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => supplierCalendarsRoute,
  component: lazyRouteComponent(() => import("./+CalendarsPage")),
});
