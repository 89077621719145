import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { resourcesRoute } from "..";

export const resourceRoute = createRoute({
  path: "$resourceId",
  getParentRoute: () => resourcesRoute,
  component: lazyRouteComponent(() => import("./+ResourcePage")),
  beforeLoad: () => ({
    breadcrumb: "Edit Resource",
  }),
});
