import { Outlet, createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { settingsRoute } from "..";

export const usersSettingsRoute = createRoute({
  path: "users",
  getParentRoute: () => settingsRoute,
  component: Outlet,
});

export const usersSettingsIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => usersSettingsRoute,
  component: lazyRouteComponent(() => import("./+Users")),
});
