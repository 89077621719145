import { LocalDayTimeRange, TimeLocal } from "@givenwell/management-api";
import { AvailableTimeDayModel } from "./model";

type LocalTimeRange = { start: TimeLocal; end: TimeLocal };

export class AvailableTimeTransformer {
  static hasOverlappingRanges(day: AvailableTimeDayModel): boolean {
    const blocks = day.blocks.filter(block => block.start && block.end);

    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i]!;
      if (block.start === undefined || block.end === undefined) continue;

      for (let j = i + 1; j < blocks.length; j++) {
        const otherBlock = blocks[j]!;
        if (otherBlock.start === undefined || otherBlock.end === undefined) continue;

        if (this.overlaps(block as LocalTimeRange, otherBlock as LocalTimeRange)) {
          return true;
        }
      }
    }
    return false;
  }

  static toMinutes(block: TimeLocal): number {
    return block.hour * 60 + block.minute;
  }

  static overlaps(block: LocalTimeRange, otherBlock: LocalTimeRange) {
    const start = this.toMinutes(block.start);
    const end = this.toMinutes(block.end);
    const otherStart = this.toMinutes(otherBlock.start);
    const otherEnd = this.toMinutes(otherBlock.end);

    return start < otherEnd && end > otherStart;
  }

  static toAvailableTimeDayModels(available_times: LocalDayTimeRange[]): AvailableTimeDayModel[] {
    const results: AvailableTimeDayModel[] = [];
    const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"] as const;

    for (const day of days) {
      const blocks = available_times.filter(time => time.day_of_week === day);
      const model = {
        day: day,
        enabled: blocks.length > 0,
        blocks: blocks.map(time => ({ key: this.randomKey(), start: time.start_time, end: time.end_time })),
      };
      results.push(model);
    }
    return results;
  }

  static toLocalDayTimeRanges(days: AvailableTimeDayModel[]): LocalDayTimeRange[] {
    const enabledDays: AvailableTimeDayModel[] = days.filter(d => d.enabled);

    const result = enabledDays.flatMap(day =>
      day.blocks
        .filter(b => b.start && b.end)
        .map(d => ({
          day_of_week: day.day,
          start_time: d.start!,
          end_time: d.end!,
        }))
    );

    return result;
  }

  static isOutOfOrder(start: TimeLocal, end: TimeLocal): boolean {
    return start.hour > end.hour || (start.hour === end.hour && start.minute >= end.minute);
  }

  static randomKey(): string {
    return Math.random().toString(36).substring(7);
  }
}
