import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { suppliersRoute } from "..";

export const supplierRoute = createRoute({
  path: "$supplierId",
  getParentRoute: () => suppliersRoute,
  component: lazyRouteComponent(() => import("./+SupplierLayout")),
  beforeLoad: () => ({
    breadcrumb: "Edit Supplier",
  }),
});

export const supplierIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => supplierRoute,
  component: lazyRouteComponent(() => import("./+SupplierPage")),
});
