import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { supplierRoute } from "..";

export const messagesRoute = createRoute({
  path: "messages",
  getParentRoute: () => supplierRoute,
  component: lazyRouteComponent(() => import("./+MessagesLayout")),
});

export const messagesIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => messagesRoute,
  component: lazyRouteComponent(() => import("./+Messages")),
});
